// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alertModal {
  width: 500px;
  background: #ffffff;
  padding: 14px;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 0 auto;
  padding-top: 22px;
}

.alertModal button {
  margin-top: 20px;
  background-color: #2776ff;
  color: #fff;
  font-weight: 600;
}

.alertModal button:hover {
  background-color: #2776ff;
}


/* for Duplicate pages start  */
.captcha_wrapper{
  padding: 1rem 2rem;
}
canvas { 
  border: 2px solid #0a64f9; 
  border-radius: 4px; 
  cursor: pointer;
} 
.captue_Img_Reload{
  display: flex;
}
#reload-button{
 padding: 1rem;
}
/* for Duplicate pages End */
`, "",{"version":3,"sources":["webpack://./src/Styles/AlertModal.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;;AAGA,+BAA+B;AAC/B;EACE,kBAAkB;AACpB;AACA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,aAAa;AACf;AACA;CACC,aAAa;AACd;AACA,4BAA4B","sourcesContent":[".alertModal {\n  width: 500px;\n  background: #ffffff;\n  padding: 14px;\n  box-sizing: border-box;\n  border-radius: 5px;\n  margin: 0 auto;\n  padding-top: 22px;\n}\n\n.alertModal button {\n  margin-top: 20px;\n  background-color: #2776ff;\n  color: #fff;\n  font-weight: 600;\n}\n\n.alertModal button:hover {\n  background-color: #2776ff;\n}\n\n\n/* for Duplicate pages start  */\n.captcha_wrapper{\n  padding: 1rem 2rem;\n}\ncanvas { \n  border: 2px solid #0a64f9; \n  border-radius: 4px; \n  cursor: pointer;\n} \n.captue_Img_Reload{\n  display: flex;\n}\n#reload-button{\n padding: 1rem;\n}\n/* for Duplicate pages End */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
