// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../Assests/Fonts/circular-bold.TTF", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../Assests/Fonts/circulur-medium.TTF", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../Assests/Fonts/circulur-std-black.TTF", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../Assests/Fonts/circulur-std-book.TTF", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../Assests/Fonts/FontsFree-Net-Circular-Std-Medium.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: CircularBold;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

@font-face {
  font-family: CircularMedium;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

@font-face {
  font-family: CircularSTDBlack;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}

@font-face {
  font-family: CircularSTDBook;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}

@font-face {
  font-family: CircularSTDMedium;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
}

.mix-and-match-design .sub-title {
  font-size: 14px;
  color: #858585;
  font-family: CircularSTDBook !important;
}

.single-deal {
  /* width: 75% !important; */
  /* background-color: red !important; */
}

.deal-title {
  font-size: 20px;
  color: #000;
  font-family: CircularBold !important;
  line-height: 1.25;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70%; */
  /* background-color: pink; */
}

.offer-desc {
  font-size: 16px;
  color: #0e9b4f;
  font-family: CircularMedium !important;
}

.mix-and-match-design .label {
  font-size: 16px;
  color: #000;
  font-family: CircularSTDMedium !important;
}
`, "",{"version":3,"sources":["webpack://./src/Styles/MixAndMatch.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,4CAA8C;AAChD;;AAEA;EACE,2BAA2B;EAC3B,4CAAgD;AAClD;;AAEA;EACE,6BAA6B;EAC7B,4CAAmD;AACrD;;AAEA;EACE,4BAA4B;EAC5B,4CAAkD;AACpD;;AAEA;EACE,8BAA8B;EAC9B,4CAAkE;AACpE;;AAEA;EACE,eAAe;EACf,cAAc;EACd,uCAAuC;AACzC;;AAEA;EACE,2BAA2B;EAC3B,sCAAsC;AACxC;;AAEA;EACE,eAAe;EACf,WAAW;EACX,oCAAoC;EACpC,iBAAiB;EACjB;;;mBAGiB;EACjB,4BAA4B;AAC9B;;AAEA;EACE,eAAe;EACf,cAAc;EACd,sCAAsC;AACxC;;AAEA;EACE,eAAe;EACf,WAAW;EACX,yCAAyC;AAC3C","sourcesContent":["@font-face {\n  font-family: CircularBold;\n  src: url(\"../Assests/Fonts/circular-bold.TTF\");\n}\n\n@font-face {\n  font-family: CircularMedium;\n  src: url(\"../Assests/Fonts/circulur-medium.TTF\");\n}\n\n@font-face {\n  font-family: CircularSTDBlack;\n  src: url(\"../Assests/Fonts/circulur-std-black.TTF\");\n}\n\n@font-face {\n  font-family: CircularSTDBook;\n  src: url(\"../Assests/Fonts/circulur-std-book.TTF\");\n}\n\n@font-face {\n  font-family: CircularSTDMedium;\n  src: url(\"../Assests/Fonts/FontsFree-Net-Circular-Std-Medium.ttf\");\n}\n\n.mix-and-match-design .sub-title {\n  font-size: 14px;\n  color: #858585;\n  font-family: CircularSTDBook !important;\n}\n\n.single-deal {\n  /* width: 75% !important; */\n  /* background-color: red !important; */\n}\n\n.deal-title {\n  font-size: 20px;\n  color: #000;\n  font-family: CircularBold !important;\n  line-height: 1.25;\n  /* white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  max-width: 70%; */\n  /* background-color: pink; */\n}\n\n.offer-desc {\n  font-size: 16px;\n  color: #0e9b4f;\n  font-family: CircularMedium !important;\n}\n\n.mix-and-match-design .label {\n  font-size: 16px;\n  color: #000;\n  font-family: CircularSTDMedium !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
