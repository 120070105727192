// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.importdata_section{
    color: #818181;
    list-style-type: none;
    font-family: CircularSTDBook !important;
    font-size: 14px;
    position: relative;}




.q-import-main-page {
    margin: 0px 50px;
}
`, "",{"version":3,"sources":["webpack://./src/Styles/ImportData.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,qBAAqB;IACrB,uCAAuC;IACvC,eAAe;IACf,kBAAkB,CAAC;;;;;AAKvB;IACI,gBAAgB;AACpB","sourcesContent":[".importdata_section{\r\n    color: #818181;\r\n    list-style-type: none;\r\n    font-family: CircularSTDBook !important;\r\n    font-size: 14px;\r\n    position: relative;}\r\n\r\n\r\n\r\n\r\n.q-import-main-page {\r\n    margin: 0px 50px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
