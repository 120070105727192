// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.employeeicon{
    width: auto;
    height: auto;
    float: left;
}
.employee-sort{
    width: 5%;
}
.employee-name{
    width: 20%;
}
.employee-phone{
    width: 15%;
}
.employee-email{
    width: 25%;
}
.employee-pin{
    width: 5%;
}
.employee-role{
    width: 10%;
}
.employee-iconall{
    width: 20%;
}`, "",{"version":3,"sources":["webpack://./src/Styles/Settings/Employee.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,WAAW;AACf;AACA;IACI,SAAS;AACb;AACA;IACI,UAAU;AACd;AACA;IACI,UAAU;AACd;AACA;IACI,UAAU;AACd;AACA;IACI,SAAS;AACb;AACA;IACI,UAAU;AACd;AACA;IACI,UAAU;AACd","sourcesContent":[".employeeicon{\r\n    width: auto;\r\n    height: auto;\r\n    float: left;\r\n}\r\n.employee-sort{\r\n    width: 5%;\r\n}\r\n.employee-name{\r\n    width: 20%;\r\n}\r\n.employee-phone{\r\n    width: 15%;\r\n}\r\n.employee-email{\r\n    width: 25%;\r\n}\r\n.employee-pin{\r\n    width: 5%;\r\n}\r\n.employee-role{\r\n    width: 10%;\r\n}\r\n.employee-iconall{\r\n    width: 20%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
