// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Inside your CSS file or style block */

.main_store_details{
    background-color: white;
    padding: 8px;
    margin-top: 4px;
    margin-left: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    opacity: 1;
    width:100%
  }
  .custom-container{
    width:90%;
  }
  
  .btn {
    padding: 12px 24px;
    border-radius: 8px;
    font-size: 20px;
    outline: none;
    cursor: pointer;
  }
  
  .active-tab {
    background-color: #ebf2ff;
    color: #0a64f9;
  }
  
  .inactive-tab {
    background-color: white;
    color: #6a6a6a;
  }
  
  .MuiPopover-paper.MuiMenu-paper  {
    max-height: 36vh;
  }`, "",{"version":3,"sources":["webpack://./src/Styles/MainInStore.css"],"names":[],"mappings":"AAAA,wCAAwC;;AAExC;IACI,uBAAuB;IACvB,YAAY;IACZ,eAAe;IACf,iBAAiB;IACjB,wCAAwC;IACxC,kBAAkB;IAClB,UAAU;IACV;EACF;EACA;IACE,SAAS;EACX;;EAEA;IACE,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,aAAa;IACb,eAAe;EACjB;;EAEA;IACE,yBAAyB;IACzB,cAAc;EAChB;;EAEA;IACE,uBAAuB;IACvB,cAAc;EAChB;;EAEA;IACE,gBAAgB;EAClB","sourcesContent":["/* Inside your CSS file or style block */\r\n\r\n.main_store_details{\r\n    background-color: white;\r\n    padding: 8px;\r\n    margin-top: 4px;\r\n    margin-left: 10px;\r\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\r\n    border-radius: 8px;\r\n    opacity: 1;\r\n    width:100%\r\n  }\r\n  .custom-container{\r\n    width:90%;\r\n  }\r\n  \r\n  .btn {\r\n    padding: 12px 24px;\r\n    border-radius: 8px;\r\n    font-size: 20px;\r\n    outline: none;\r\n    cursor: pointer;\r\n  }\r\n  \r\n  .active-tab {\r\n    background-color: #ebf2ff;\r\n    color: #0a64f9;\r\n  }\r\n  \r\n  .inactive-tab {\r\n    background-color: white;\r\n    color: #6a6a6a;\r\n  }\r\n  \r\n  .MuiPopover-paper.MuiMenu-paper  {\r\n    max-height: 36vh;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
